@import url('../../style.react.scss');

:root {
  --marketing-card-border: 1px solid #DBDFE9;
}

@media only screen and (max-width: 768px) {

  #dca-college-search-wrapper {
    margin: 0 auto;
    align-items: center;
    width: 90vw;
  }

  .dca-card-container {
    width: 100% !important;
    margin: 0px 0px !important;
  }

  .dca-test-score-card-item,
  .dca-card-item {
    width: 90vw !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .dca-card-item {
    padding: 0px 20px !important;
  }

  .dca-search-title {
    // font-size: 21px;
    font-size: 23px;
    line-height: 1.2;
    font-family: 'ColfaxWebMedium';
    align-self: flex-start !important;
  }

  .dca-search-description {
    font-size: 16px;
    font-family: 'ColfaxWebRegular';
    margin-top: 15px;
    margin-right: 0px !important;
    width: 90vw;
    max-width: 100vw !important;
    line-height: 164.4%;
  }

  .dca-search-header-title-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;
  }

  .dca-college-search-resources-links-header {
    font-family: var(--font-M);
    font-size: 12px;
    line-height: 176.4%;
    letter-spacing: calc(0.12*12px);
  }

  .dca-college-search-resources-links {
    font-family: var(--font-M);
    font-size: 15px;
    line-height: 176.4%;
    text-decoration: underline !important;
    cursor: pointer;
    width: fit-content;
  }

  .dca-card-items-found-text {
    font-size: 15px;
    line-height: 164.4%;
  }

  .dca-card-item-nameField {
    font-size: 18px;
    text-align: center;
  }

  .dca-card-item-nameField-major {
    font-size: 18px;
    text-align: center;
    max-width: 85%
  }

  .dca-college-search-card-location-text {
    font-size: 14px;
    line-height: 0px;
  }

  .dca-search-header-wrapper {
    margin: 30px 0px;
  }

  .dca-college-card-data-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 18px;
    column-gap: 15px;
    width: 100%;
    margin: 20px 0;
  }


  .dca-college-card-data-wrapper>.d-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dca-college-search-filters-button,
  .dca-college-search-filters-button-bolded {
    border: transparent;
    border-radius: 13px;
    background: #FAFAFA;
    font-family: var(--font-M);
    height: 50px;
    font-size: 15px;
    width: 100%;
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
  }

  .dca-college-search-filters-button-bolded {
    border: 2px solid black;
  }

  .dca-college-filters-button-icon {
    width: 21px;
    height: 21px;
  }

  .dca-college-search-mobile-display-sort-content {
    display: flex;
    flex-direction: row;
    // margin-right: 10px;
    // width: 80% !important;
  }

  .dca-college-search-by-sort-text {
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    color: #585555;
    margin-bottom: 2px;
    align-self: flex-end;
    font-family: var(--font-M);
  }

  .dca-college-search-mobile-display-sort {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 90vw;
    font-size: 15px;
  }

  .dca-search-select-sort-mobile {
    // border-radius: 38px;
    // border: 1px solid #EDEDED;
    // height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--font-M);
    text-align: center;
    vertical-align: middle;
    line-height: 176.4%;
    letter-spacing: calc(0.12*10px);
  }

  .dca-search-select-sort-mobile-label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    width: fit-content;
  }

  .dca-card-item-header {
    font-size: 10px;
    line-height: 176.4%;
    letter-spacing: calc(0.12*10px);
  }

  .dca-card-item-text {
    font-size: 15px;
  }

  .dca-college-search-card-data-column-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
    text-align: left;
  }

  .dca-college-search-filter-drawer-container {
    position: relative;
  }

  .dca-college-search-filter-drawer {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: -100%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    animation: slideDown 0.8s ease forwards;
    z-index: 1000;

    max-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .dca-college-search-filter-drawer-open {
    position: fixed;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.8s ease forwards;
    z-index: 1000;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .dca-college-search-filter-drawer-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 0px;
  }

  .dca-college-search-mobile-filters-title {
    font-family: var(--font-M);
    font-size: 17px;
    text-align: center;
    vertical-align: middle;
  }

  .dca-college-search-filter-drawer-close-button {
    cursor: pointer;
    right: 20px;
    text-align: center;
    font-size: 24px;
    background: none;
    border: none;
    color: grey;
    z-index: 10;
    position: absolute;
  }

  .dca-college-search-mobile-filters-wrapper {
    width: 90vw;
    max-width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  .dca-college-search-mobile-filters-drawer-footer {
    position: sticky;
    bottom: 0;
    background: white;
    border-top: 1px solid #EDEDED;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    z-index: 1001;
  }

  .dca-college-search-mobile-filters-drawer-footer-clear {
    font-size: 16px;
    font-family: var(--font-M);
    text-decoration: underline;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 33%;
    justify-content: center;
  }

  .dca-college-search-mobile-filters-drawer-footer-view-results {
    border-radius: 13px;
    background: #d5fbab;
    border: transparent;
    font-size: 16px;
    font-family: var(--font-M);
    height: 50px;
    text-align: center;
    vertical-align: middle;
    margin: 18px 0px;
    // padding: 0px 80px;
    width: 60%;
    max-width: 280px;
    color: black
  }

  .dca-college-search-sort-carat-a,
  .dca-college-search-sort-carat-b {
    height: 12.35px;
    width: 10px;
    margin-left: 10px;
  }

  .dca-college-search-sort-carat-b {
    transform: rotate(180deg);
  }

  .dca-advisor-connect-button-inline-mobile {
    margin-right: 15px;
    background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
    white-space: nowrap;
    border-radius: 10px;
    font-family: 'ColfaxWebMedium';
  }

  .dca-advisor-connect-button-card-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 43px;
    width: 100%;
    background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
    border-radius: 10px;
    border: transparent;
    font-family: var(--font-M);
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }

  .dca-connectbuttonincardnoauth-mobile {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    // height: 100%;
    // min-height: 43px;
    height: 50px;
    width: 90%;
    background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
    border-radius: 13px;
    border: transparent;
    font-family: var(--font-M);
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }

  .dca-view-advisor-button {
    font-size: 15px !important;
  }

  .dca-college-search-arrow-up-arrow-down {
    width: 13px;
    height: 12.54px;
  }

  .dca-filters-active-icon {
    display: inline-block;
    background-color: black;
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    margin-left: 10px;
    padding-top: 0.5px;
  }

  .dca-advisor-card {
    width: 100% !important;
  }

  #dca-college-search-selectivity-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #kt_slider_selectivity,
  #kt_slider_popularity_mobile,
  #kt_slider_earnings_mobile,
  #kt_slider_cost_mobile,
  #kt_slider_act_mobile,
  #kt_slider_sat_mobile {
    width: 85%;
    max-width: 85% !important;
  }

  @keyframes slideUp {
    from {
      bottom: -90vh;
      opacity: 0;
    }

    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      bottom: 0;
      opacity: 1;
    }

    to {
      bottom: -90vh;
      opacity: 0;
    }
  }

}