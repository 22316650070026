@import url('../style.react.scss');

.dca-footer-wrapper {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dca-footer-links-wrapper {
  display: flex;
  flex-direction: row;
}

.dca-footer-tools-resources-links {
  display: flex;
  flex-direction: row;
}

.dca-footer-link-column {
  display: flex;
  flex-direction: column;
  margin-right: 75px;
}

.dca-footer-group-title {
  font-family: var(--font-M);
  font-size: 14.5px;
  letter-spacing: calc(.12*14.5px);
  line-height: 268.4%;
}

.dca-footer-link:hover,
.dca-footer-sublink:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.dca-footer-link {
  font-family: var(--font-M);
  font-size: 18px;
  line-height: 218%;
  color: black;
}

.dca-footer-link-group-header {
  font-family: var(--font-M);
  font-size: 18px;
  line-height: 218%;
}

.dca-footer-sublink {
  font-size: 16px;
  line-height: 231%;
  padding-left: 17px;
}

.dca-footer-copyright {
  font-family: var(--font-M);
  font-size: 15px;
  line-height: 176.4%;
  margin-top: 4vh;
  margin-bottom: 3vh;
}

@media only screen and (max-width: 768px) {

  .dca-footer-link-column {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }

  .dca-footer-group-title {
    font-family: var(--font-M);
    font-size: 10.5px;
    letter-spacing: calc(.12*10.5px);
    line-height: 268.4%;
  }

  .dca-footer-link {
    font-family: var(--font-M);
    font-size: 12px;
    line-height: 218%;
    color: black;
  }

  .dca-footer-link-group-header {
    font-family: var(--font-M);
    font-size: 13px;
    line-height: 218%;
  }

  .dca-footer-sublink {
    font-size: 12px;
    line-height: 231%;
    padding-left: 13px;
  }

  .dca-footer-copyright {
    font-family: var(--font-M);
    font-size: 12px;
    line-height: 176.4%;
    margin-top: 4vh;
    margin-bottom: 3vh;
  }

}