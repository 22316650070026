:root {
  --font-size-standard: 20px;
  --font-size-subtitle: 26px;
  --font-size-title: 40px;
  --font-size-vertical-tab: 14px;

  --font-size-mobile-standard: 16px;
  --font-size-mobile-subtitle: 18px;
  --font-size-mobile-title: 23px;
  --font-size-mobile-vertical-tab: 12px
}

.dca-overview-breadcrumb {
  margin-bottom: 5vh;
}

.dca-overview-ordered-item {
  display: inline-block;
  margin-left: 50px;
  font-size: var(--font-size-standard);
  line-height: 1.75em;
  align-self: self-start;
}

.dca-overview-title {
  font-size: var(--font-size-title);
  font-family: 'ColfaxWebMedium';
  text-align: center;
}

.dca-overview-paragraph {
  margin-top: 40px;
  font-size: var(--font-size-standard);
  line-height: 1.75em;
  align-self: self-start;
}

.dca-overview-subheading {
  margin-top: 40px;
  line-height: 1.75em;
  font-size: var(--font-size-subtitle);
  font-family: 'ColfaxWebMedium';
  font-weight: 100;
  align-self: self-start;
}

.dca-overview-vertical-tabs {
  width: 20%;
  max-width: fit-content;
  // margin: 0px 4vw;
  margin-left: 4vw;
}

.dca-overview-vertical-tab,
.dca-overview-vertical-tab-active {
  padding: 10px;
  max-width: 100%;
  overflow-wrap: break-word;
  // hyphens: auto;
  border-radius: 8px;
  cursor: pointer;
  font-size: var(--font-size-vertical-tab);
}

.dca-overview-vertical-tab-active {
  background: #DAF8B9;
}

.dca-overview-cta-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--font-size-standard);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin: 60px 0px 30px 0px;
  line-height: 1.75em;
}

.dca-overview-cta-text {
  font-family: 'ColfaxWebMedium';
  font-size: var(--font-size-standard);
  padding: 35px;
}

.dca-overview-cta-button {
  margin-right: 30px;
  max-height: fit-content;
  min-width: fit-content;
  border-radius: 10px;
  border: transparent;
  padding: 10px 20px;
  box-sizing: border-box;
  font-family: 'ColfaxWebMedium';
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  background: #DAF8B9
}

.dca-overview-navlink {
  cursor: pointer;
  color: #457AB0;
  font-family: 'ColfaxWebMedium';
  // color: #98f29b;
  // color: #7ac57d
}

.dca-overview-navlink:hover {
  text-decoration: underline;
}

.dca-overview-font-standard {
  font-size: var(--font-size-standard);
}

.dca-overview-sidebar {
  visibility: hidden;
  display: none;
}

.dca-overview-main-content {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

////////////////////////////////
//////// Mobile styling ////////
////////////////////////////////
@media only screen and (max-width: 767px) {


  .dca-overview-sidebar {
    visibility: visible;
    display: block;
  }

  .dca-overview-ordered-item {
    display: inline-block;
    margin-left: 25px;
    font-size: var(--font-size-mobile-standard);
    line-height: 1.75em;
    align-self: self-start;
  }

  .dca-overview-font-standard {
    font-size: var(--font-size-mobile-standard);
  }

  .dca-overview-title {
    font-size: var(--font-size-mobile-title);
    font-family: 'ColfaxWebMedium';
    text-align: center;
  }

  .dca-overview-paragraph {
    margin-top: 20px;
    font-size: var(--font-size-mobile-standard);
    line-height: 1.75em;
    align-self: self-start;
  }

  .dca-overview-subheading {
    margin-top: 20px;
    line-height: 1.75em;
    font-size: var(--font-size-mobile-subtitle);
    font-family: 'ColfaxWebMedium';
    font-weight: 100;
    align-self: self-start;
  }

  .dca-overview-vertical-tabs {
    visibility: hidden;
    display: none;
  }

  .dca-overview-vertical-tab,
  .dca-overview-vertical-tab-active {
    padding: 5px;
    font-size: var(--font-size-mobile-vertical-tab);
  }

  .dca-overview-cta-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: var(--font-size-mobile-standard);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    margin: 30px 0px 15px 0px;
    line-height: 1.75em;
  }

  .dca-overview-cta-text {
    font-family: 'ColfaxWebMedium';
    font-size: var(--font-size-mobile-standard);
    padding: 15px;
  }

  .dca-overview-cta-button {
    margin-right: 0px;
    margin-bottom: 10px;
    max-height: fit-content;
    min-width: fit-content;
    border-radius: 10px;
    border: transparent;
    padding: 10px 20px;
    box-sizing: border-box;
    font-family: 'ColfaxWebMedium';
    white-space: nowrap;
    overflow: auto;
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    background: #DAF8B9
  }

  .dca-overview-main-content {
    width: 80%;
  }

}