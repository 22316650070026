@import url('../style.react.scss');

:root {
  --mobile-fs-title: 21px;
  --mobile-fs-body: 16px;
  --mobile-fs-body-b: 13px;
  --mobile-fs-link: 15px
}

#dca-dashboard-title-wrapper {
  display: flex;
  flex-direction: column;
  // text-align: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 35px;
}

#dca-dashboard-title {
  font-size: 32px;
  font-family: var(--font-M);
  font-weight: normal;
  margin-bottom: 15px;
}

#dca-dashboard-description {
  font-size: 21px;
  line-height: 38px;
  text-align: center;
  // margin-top: 15px;
  max-width: 55%;
}

#dca-dashboard-content-blocks-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#dca-dashboard-intro-flow-wrapper {
  display: flex;
  flex-direction: column;
  // width: 45%;
  width: 80%;
  margin: 0 auto;
}

.dca-dashboard-card {
  display: flex;
  flex-direction: column;
  width: 61%;
  max-width: 1008px;
  // margin-top: 30px;
}

// #992 bootstrap accordion causes elements in accordion header to adjust slightly because border bottom in accordion button section takes border into account with height val, no override so instead we work around by applying border to visually separate the header from the accordion content by applying a border top in accordion-body class instead
.dca-dashboard-card .accordion-body {
  padding: 40px 0px;
  border-top: solid 1px #f1f1f2;
}

.dca-dashboard-card .accordion-item,
.dca-dashboard-card .accordion-button {
  border-radius: 24px !important;
}

.dca-dashboard-card .accordion-button:not(.collapsed) {
  background: white !important;
  background-color: white !important;
  // bs-border-color
  // border-bottom: solid 1px #f1f1f2;
  border-radius: 24px 24px 0px 0px !important;
  box-shadow: none !important;
}

.dca-dashboard-card .accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>");
  // padding-left: 19px;
}


.dca-dashboard-content-block-accordion-header {
  display: flex;
  align-items: center;
}

.dca-dashboard-content-block-accordion-header>button {
  color: black !important;
  font-family: 'ColfaxWebMedium';
}

.dca-dashboard-content-block-accordion-header .accordion-button {
  // padding: 19.5px 23px 19.5px 30px;
  padding: 0px 49px 0px 42px;
  height: 96px;
  max-height: 96px;
}

#dca-dashboard-icon-wrapper,
#dca-dashboard-premium-icon-wrapper,
#dca-dashboard-insight-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: 30px;
  // max-width: 30px;
}

#dca-dashboard-icon-wrapper {
  // margin-right: 19px;
  margin: auto 19px;
}

#dca-dashboard-insight-icon-wrapper {
  margin-left: 5px;
}

#dca-dashboard-status-circle {
  height: 27px;
  width: 27px;
  border-radius: 50%;
  margin-right: 23px;
}

.dca-dashboard-status-label {
  display: flex;
  height: 31px;
  border: transparent;
  border-radius: 7px;
  align-items: center;
  box-sizing: border-box;
}

.dca-dashboard-status-label-text {
  font-family: var(--font-M);
  font-size: 12px;
  letter-spacing: 1.44px;
  line-height: 176.4%;
  text-align: center;
  padding: 0 12px;
  margin: 0 auto;
  min-width: 0;
  white-space: nowrap;
}

// block icons
.dca-dashboard-block-icon,
.dca-dashboard-overviewIcon,
.dca-dashboard-intendedMajorIcon,
.dca-dashboard-ecIcon,
.dca-dashboard-collegeInterestIcon,
.dca-dashboard-standardizedIcon,
.dca-dashboard-collegeAppIcon {
  object-fit: contain;
}

.dca-dashboard-block-icon,
.dca-dashboard-overviewIcon,
.dca-dashboard-intendedMajorIcon,
.dca-dashboard-ecIcon,
.dca-dashboard-collegeInterestIcon,
.dca-dashboard-standardizedIcon {
  width: 26px;
}

.dca-dashboard-block-icon {
  max-height: 20px;
}

.dca-dashboard-overviewIcon {
  width: 28px;
  max-height: 23px;
}

.dca-dashboard-intendedMajorIcon {
  max-height: 20px;
}

.dca-dashboard-ecIcon {
  max-height: 24px;
}

.dca-dashboard-collegeInterestIcon {
  max-height: 24px;
}

.dca-dashboard-standardizedIcon {
  max-height: 33px;
}

.dca-dashboard-collegeAppIcon {
  width: 35px;
  max-height: 40px;
  padding-top: 5px;
}



// // // // // // //

.dca-dashboard-block-title {
  margin-top: 2px;
  font-size: 23px;
  font-family: var(--font-M);
  // padding-right: 19px;
  width: 100%;
  margin-right: 15px;
}

.dca-dashboard-button,
.dca-dashboard-wizard-button {
  font-size: 19px;
  font-family: var(--font-M);
  font-weight: normal;
  align-items: center;
  border-radius: var(--gray-bttn-radius);
  border: transparent;
  font-family: 'ColfaxWebMedium';
  background: #D5FBAB;
}

.dca-dashboard-button {
  min-width: 280px;
  width: 57.8%;
  height: 52px;
  max-width: 583px;
  margin-right: auto;
  margin-left: auto;
}

.dca-dashboard-wizard-button {
  height: 50px;
  width: 240px;
  margin: 40px auto;
}

.dca-dashboard-block-intro-text {
  font-size: 20px;
  line-height: 38px;
  padding: 0px 48px;
}

// ------------------------------------------------ //
// RESOURCES & INSIGHTS
.dca-dashboard-block-resources-wrapper,
.dca-dashboard-block-insights-wrapper {
  display: flex;
  flex-direction: column;
}

.dca-dashboard-block-resources-wrapper {
  // margin: 33px 48px 0px 48px;
  margin-top: 33px;
}

.dca-dashboard-block-insights-wrapper {
  margin-top: 23px;
}

.dca-dashboard-block-resources-item-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  margin-left: 33px;
  margin-right: 48px;
  border-radius: 16px;
}

.dca-dashboard-block-resources-item-wrapper:hover {
  background-color: #EFFDF0;
}

.dca-dashboard-block-resources-title,
.dca-dashboard-block-insights-title {
  font-size: 12px;
  font-family: var(--font-M);
  line-height: 1.764;
  letter-spacing: 1.44px;
  margin-left: 48px;
}

.dca-dashboard-block-resources-title {
  margin-bottom: 8px;
}

.dca-dashboard-block-insights-title {
  margin-bottom: 16px;
}

.dca-dashboard-block-resource-title {
  font-family: var(--font-M);
  cursor: pointer;
  font-size: 19px;
}

.dca-dashboard-block-resource-description {
  // font-family: var(--font-M);
  font-size: 18px;
  // line-height: 34px;
  margin-top: 3px;
}

.dca-dashboard-block-premium-icon {
  width: 29px;
  object-fit: contain;
}

.dca-dashboard-block-insight-icon {
  width: 32px;
  object-fit: contain;
}

.dca-dashboard-block-insights-link {
  font-size: 19px;
  font-family: var(--font-M);
  line-height: 40px;
  cursor: pointer;
  color: black;
}

.dca-dashboard-block-insights-link:hover {
  text-decoration: underline !important;
}

// ------------------------------------------------ //
// INTRO FLOW

.dca-dashboard-introflow-step-text {
  font-family: var(--font-M);
  font-size: 14px;
  letter-spacing: 1.68px;
  line-height: 176.4%;
  white-space: nowrap;
}

.dca-intro-backArrow {
  color: black !important;
}

.dca-intro-question-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 32px;
}

.dca-intro-back-link {
  font-family: var(--font-M);
  font-size: 17px;
  white-space: nowrap;
  cursor: pointer;
}

.dca-introflow-question-text {
  font-family: var(--font-M);
  font-size: 22px;
  font-weight: normal;
  line-height: 147.7%;
  text-align: center;
  // white-space: nowrap;
  padding: 0 30px;
}

.dca-dashboard-intro-radio,
.dca-dashboard-intro-radio-custom-intended-majors-not-sure {
  display: flex;
  align-items: center;
  font-size: 19px;
  font-family: var(--font-M);
  font-weight: normal;
  cursor: pointer;
  max-width: 578px;
  border-radius: 10px !important;
  border: 1px solid #D9D9D9 !important;
  white-space: nowrap;
  padding: 10px 18px;
  // padding: 15px 18px;
  min-width: 280px;
  width: 100%;
  // max-width: 365px;
}

.dca-dashboard-intro-radio-custom-intended-majors-not-sure {
  border: transparent !important;
  margin-top: 10px;
}

.dca-dashboard-intro-radio>input[type="radio"],
.dca-dashboard-intro-radio-custom-intended-majors-not-sure>input[type="radio"] {
  margin-right: 15px;
}

.dca-dashboard-intro-radio:hover {
  // border: 1px solid #999999 !important;
  border: 1px solid #BABABA !important;
  cursor: pointer;
}

.dca-dashboard-intro-radio.radio-selected,
.dca-Calendar.radio-selected {
  border: 1px solid #00E282 !important;
  border-radius: 13px !important;
}

.dca-dashboard-intro-radio>.form-check-input:checked,
.dca-dashboard-intro-radio-custom-intended-majors-not-sure>.form-check-input:checked {
  background-color: transparent;
  border: transparent;
}

.dca-dashboard-intro-radio.circle>.form-check-input {
  height: 29px;
  width: 30px;

  min-height: 29px;
  min-width: 30px;
}

.dca-dashboard-intro-radio.circle>.form-check-input:checked {
  background-image: url('../../../../custom_assets/dashboard/circle_checked.png');
  // background-size: contain;
}

.dca-dashboard-intro-radio.box>input[type="radio"],
.dca-dashboard-intro-radio-custom-intended-majors-not-sure>input[type="radio"] {
  border-radius: 4px;
  min-width: 1.75rem;
  min-height: 1.75rem;
}

.dca-dashboard-intro-radio.box>.form-check-input:checked,
.dca-dashboard-intro-radio-custom-intended-majors-not-sure>.form-check-input:checked {
  background-image: url('../../../../custom_assets/dashboard/box_checked.png');
  // background-size: cover;
}

.dca-Calendar>span,
.dca-Calendar>input {
  border-radius: 13px !important;
}

.dca-dashboard-progressTracker-wrapper {
  display: flex;
  margin: 25px 48px 35px 48px;
  // margin: 34px 48px;
}

.dca-dashboard-progressTracker {
  display: flex;
  flex-direction: row;
  border-radius: 18px;
  position: relative;
  width: 100%;
}

.dca-dashboard-progressTracker-text {
  font-size: 20px;
  line-height: 176.4%;
  margin-left: 74px;
  padding: 22px 22px 4px 0;
}

.dca-dashboard-progressTracker-link,
.dca-dashboard-progressTracker-link-inactive {
  font-family: var(--font-M);
  font-size: 19px;
  line-height: 176.4%;
}

.dca-dashboard-progressTracker-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dca-dashboard-progressTracker-icon {
  width: 25px;
  // width: 23px;
  position: absolute;
  top: 31px;
  left: 28px;
}

.dca-dashboard-progressTracker.red {
  background: rgb(255, 86, 89, 9%);
}

.dca-dashboard-progressTracker.yellow {
  background: rgb(255, 237, 70, 13%);
}

.dca-dashboard-progressTracker.green {
  background: rgb(220, 251, 221, 45%);
}

.dca-dashboard-progressTracker-link-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin: 5px 0 18px 74px;
  margin: 7px 0 18px 74px;
}

.dca-dashboard-progressTracker-link-icon {
  height: 13px;
}

.dca-dashboard-wizard-home-icon {
  width: 25px;
  cursor: pointer;
}

.dca-dashboard-wizard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 42px;
}

.dca-dashboard-wizard-form-wrapper {
  margin: 17px auto 0 auto;
  // margin: 33px auto 0 auto;
  display: flex;
  flex-direction: column;
  // width: 100%;
  width: max-content;
}

// multiselect under wizard
.dca-dashboard-wizard-form-wrapper .css-b62m3t-container,
.dca-dashboard-select-label {
  width: 563px;
  max-width: 90%;
  margin: 0 auto;
}

.dca-dashboard-wizard-form-wrapper .css-b62m3t-container {
  align-self: center;
}

.dca-dashboard-select-label {
  align-self: flex-start;
}

.dca-dashboard-wizard-select-multiple-note {
  font-size: 17px;
  line-height: 147.7%;
  text-align: center;
  margin-top: 8px;
  padding: 0px 30px;
}

.dca-dashboard-wizard-skip-wizard-link {
  font-family: var(--font-M);
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 19px;
}

.dca-dashboard-wizard-sub-question {
  font-size: 17px;
  color: #4C4C4C;
  line-height: 33px;
  text-align: center;
  padding: 0 30px;
}

.dca-dashboard-standardized-3b-text {
  font-size: 19px;
  line-height: 36px;
  width: 56.8%;
  max-width: 573px;
  min-width: 500px;
  margin: 55px auto 0px auto;
}

.dca-dashboard-editscore-input {
  padding: 0.5rem 1rem;
  background: rgba(250, 250, 250, 1);
  border: transparent;
  width: 280px;
  max-width: 280px;
}

.dca-dashboard-signin-reminder {
  display: flex;
  align-self: flex-start;
  margin-bottom: 36px;
  width: fit-content;
}

.dca-dashboard-signin-reminder-text {
  font-size: 20px;
  color: black;
  display: inline;
  align-items: center;
  margin: auto 0;
}

.dca-dashboard-signin-tip-image {
  width: 25px;
  height: auto;
  margin: 2px 6px 0 0;
  object-fit: contain;
  image-rendering: crisp-edges;
}

.dca-dashboard-signin-reminder-tip {
  border: transparent;
  border-radius: 12px;
  background-color: #EFFDF0;
  font-family: var(--font-M);
  font-size: 14px;
  line-height: 176.4%;
  letter-spacing: 1.68px;
  margin-right: 20px;
  height: 40px;
  min-height: 40px;
  width: 82px;
  min-width: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dca-dashboard-signin-reminder-hover:hover {
  text-decoration: underline;
}

#dca-introFlow-Calendar .p-inputtext {
  text-align: center;
  font-size: 18px;
}

#dashboard-signin-wrapper {
  margin: 0px auto 0 auto;
}

#dca-dashboard-block-icon-sm-Extracurriculars,
#dca-dashboard-block-icon-sm-CollegeInterestList {
  height: 17px;
  margin-bottom: 4px;
}

.dca-dashboard-mobile-insights-row {
  display: flex;
  flex-direction: row;
  margin-left: 44px;
}

#dca-introflow-question-text-wizard-margin {
  margin: 50px 0 0 0
}

#dca-dashboard-select-and-label-wrapper {
  width: max-content;
}

#dca-word-break-trigger-dashboard {
  display: none;
}


















// 
// MOBILE
// 

@media only screen and (max-width: 768px) {

  #dashboard-signin-wrapper {
    margin: -20px auto 0 auto;
  }

  #dca-dashboard-title-wrapper {
    margin-bottom: 53px;
  }

  .dca-dashboard-intro-radio,
  .dca-dashboard-intro-radio-custom-intended-majors-not-sure {
    font-size: 17px;
  }

  .dca-dashboard-wizard-home-icon {
    width: 24px;
  }

  #dca-introflow-question-text-wizard-margin {
    margin: 50px 0px 8px 0px
  }

  .dca-introflow-question-text {
    font-family: var(--font-M);
    font-size: 20px;
    line-height: 157.7%;
  }

  #dca-dashboard-title {
    font-size: 23px;
  }

  .dca-dashboard-card .accordion-body {
    padding: 25px 0px;
  }

  .dca-dashboard-progressTracker-icon {
    width: 23px;
  }

  .dca-dashboard-mobile-insights-row {
    align-items: center;
    margin-left: 20px;
  }

  .dca-dashboard-block-resources-item-wrapper {
    padding: 10px 15px;
    margin-left: 9px;
    margin-right: 24px;
  }

  .dca-dashboard-block-resources-title,
  .dca-dashboard-block-insights-title {
    margin-left: 24px;
  }

  .dca-dashboard-progressTracker-wrapper {
    // margin: 34px 24px;
    margin: 23px 24px 27px 24px
  }

  // #991 reduce whitespace to left of status label
  .dca-dashboard-content-block-accordion-header .accordion-button {
    // padding: 0px 49px 0px 42px;
    padding: 0px 49px 0px 19px;
  }

  .dca-dashboard-wizard-header {
    padding: 0 19px;
  }

  #dca-dashboard-description {
    font-size: 16px;
    line-height: 164.4%;
    max-width: 90%;
  }

  .dca-dashboard-card {
    width: 90vw;
    max-width: 90vw;
  }

  .dca-dashboard-block-insights-link {
    font-size: var(--mobile-fs-link);
    line-height: 21px;
  }

  .dca-dashboard-block-insight-icon {
    width: 28px;
    margin-bottom: 5px;
  }

  .dca-dashboard-block-resource-title {
    // subtitle, custom size
    font-size: 15px;
  }

  .dca-dashboard-block-resource-description {
    font-size: 15px;
    // font-size: var(--mobile-fs-body)
  }

  // further reduce #991
  .dca-dashboard-block-title {
    // font-size: var(--mobile-fs-title)
    font-size: 19px;
  }

  .dca-dashboard-status-label-text {
    font-size: 11px;
  }

  .dca-dashboard-block-intro-text {
    font-size: 16px;
    line-height: 164.4%;
    padding: 0 24px;
  }

  .dca-dashboard-progressTracker-text {
    font-size: 16px;
    line-height: 164.4%;
  }

  .dca-dashboard-signin-reminder-text {
    font-size: var(--mobile-fs-link);
  }

  .dca-dashboard-progressTracker-link,
  .dca-dashboard-progressTracker-link-inactive {
    font-size: 15px;
  }

  .dca-dashboard-button,
  .dca-dashboard-wizard-button {
    font-size: 15px;
  }

  .dca-dashboard-button {
    height: 46px;
  }

  .dca-dashboard-wizard-button {
    margin: 38px auto;
  }

}












@media only screen and (max-width: 450px) {

  .dca-dashboard-signin-reminder {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  // .dca-dashboard-signin-reminder-text {
  //   margin: 0 auto;
  // }

  #dca-word-break-trigger-dashboard {
    display: block;
  }

  #dashboard-signin-wrapper {
    width: 90%;
  }

  #dca-dashboard-aside-header-mobile-exception {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #dca-dashboard-major-areas-label-mobile {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .dca-dashboard-intro-radio,
  .dca-dashboard-intro-radio-custom-intended-majors-not-sure {
    // width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    white-space: wrap;
  }

  .dca-dashboard-content-block-accordion-header .accordion-button {
    padding: 0px 19px;
  }

}











@media only screen and (max-width: 300px) {

  .dca-dashboard-wizard-button {
    width: 80%;
    min-width: fit-content;
  }

  #dca-dashboard-wizard-form-wrapper-mobile {
    width: 100%
  }

  #dca-dashboard-aside-header-mobile-exception {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 10px;
  }

  .dca-dashboard-intro-radio,
  .dca-dashboard-intro-radio-custom-intended-majors-not-sure {
    width: 80% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    white-space: wrap;
    font-size: 12px !important;
  }


}