@import url('../../style.react.scss');

:root {
  --marketing-card-border: 1px solid #EDEDED;
  --marketing-card-border-hover: 1px solid #CBF999;
  --block-bottom-margin: 78px
}

.dca-marketing-college-explorer-link {
  color: black;
  cursor: pointer;
  text-decoration: underline !important;
}

.dca-marketing-college-explorer-link:hover {
  text-decoration: underline !important;
}

.dca-marketing-text-image-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#TIB-25 {
  margin-bottom: 25px;
}

#TIB-35 {
  margin-bottom: 35px;
}

#TIB-40 {
  margin-bottom: 40px;
}

#TIB-120 {
  margin-bottom: 120px;
}

#TIB-140 {
  margin-bottom: 140px;
}

#TIB-150 {
  margin-bottom: 150px;
}

#TIB-175 {
  margin-bottom: 175px;
}

.dca-marketing-page-ec-insights-purchase-true-wrapper {
  display: flex;
  flex-direction: column;
  width: 90vw;
}

.dca-marketing-page-college-logo-and-name-wrapper {
  width: 1084px;
}

.dca-marketing-page-college-logo-and-name {
  font-family: var(--font-M);
  font-size: 29px;
  line-height: 34.8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
}

.dca-marketing-disclaimer-text {
  font-size: 15px;
  line-height: 29px;
  color: #7F7F7F;
  margin-top: 11px;
  margin-bottom: 120px;
  max-width: 53vw;
  overflow-wrap: break-word;
}

.dca-marketing-connect-email-cta {
  font-size: 18px;
  line-height: 43px;
  margin-top: 8px;
  margin-bottom: 120px;
}

//-------------------------//
//------ TESTIMONIAL ------//
//-------------------------//
.dca-marketing-testimonial-wrapper {
  margin-bottom: 138px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80vw;
  text-align: center;
}

.dca-marketing-testimonial-wrapper-B {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dca-marketing-testimonial-text {
  max-width: 31vw;
  // margin: 10vh 0 30px 0;
  margin-bottom: 30px;
  font-size: 21px;
  line-height: 43px;
  text-align: center;
}

.dca-marketing-testimonial-source {
  font-family: var(--font-M);
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
}

.dca-marketing-page-headline {
  font-family: 'ColfaxWebMedium';
  text-align: center;
  font-size: 46px;
  line-height: 55.2px;
  // margin-top: 59px;
  margin-top: 25px;
  margin-bottom: 10px;
  width: 75vw;
  overflow-wrap: break-word;
}

#dca-marketing-page-headline-ec-insights {
  width: 75vw;
}

.dca-marketing-sub-headline,
.dca-marketing-sub-headline-ec {
  text-align: center;
  font-size: 21px;
  line-height: 39px;
  max-width: 68vw;
  overflow-wrap: break-word;
  margin-bottom: 40px;
}

.dca-marketing-sub-headline {
  max-width: 68vw
}

.dca-marketing-sub-headline-ec {
  max-width: 64vw
}

.dca-marketing-pt-wrapper {
  max-width: 53vw;
  margin-bottom: 30px;
}

.dca-marketing-pt-wrapper-B {
  max-width: 53vw;
  margin-bottom: 30px;
}

.dca-marketing-pt-header {
  font-family: 'ColfaxWebMedium';
  line-height: 42px;
  font-size: 23px;
}

.dca-marketing-pt-body {
  font-size: 21px;
  line-height: 43px;
}

.dca-marketing-standalone-text {
  font-size: 21px;
  line-height: 43px;
  max-width: 53vw;
  margin-bottom: 83px;
}

//-------------------------//
//-------- SELECT ---------//
//-------------------------//
.dca-marketing-page-no-select-text {
  font-size: 22px;
  font-family: var(--font-M);
  margin-top: 164px;
}

.dca-marketing-ec-select-with-insight,
.dca-marketing-ec-select {
  width: 762px;
  font-size: 23px;
  line-height: 27.6px;
  font-family: 'ColfaxWebMedium';
}

.dca-marketing-ec-select {
  align-self: flex-start;
}

.dca-marketing-ec-select-with-insight {
  // margin-bottom: 29px;
  // #675 Add more spacing below ec select 
  margin-bottom: 50px;
}

.dca-marketing-ec-select {
  margin-bottom: 40px;
}

.dca-marketing-select-dropdown .react-select__control {
  border: transparent;
  background-color: #F9F9F9;
  margin-top: 32px;
  height: 60px;
  border-radius: 16px;
  cursor: pointer;
}

.dca-marketing-select-dropdown .react-select__placeholder {
  color: #C0C0C0;
  font-size: 19px;
  line-height: 22.8px;
  margin-left: 22.8px;
}

.dca-marketing-select-dropdown .react-select__input-container {
  margin-left: 22.8px;
}

.dca-marketing-select-dropdown .react-select__input {
  font-size: 19px;
  line-height: 22.8px;
}

.dca-marketing-select-dropdown .react-select__indicator-separator {
  display: none;
}

.dca-marketing-select-dropdown .react-select__dropdown-indicator {
  color: #000000;
  margin-right: 22.8px;
}

.dca-marketing-select-dropdown .react-select__single-value {
  margin-left: 22.8px;
}

//////
/// College Select
.dca-marketing-college-select {
  width: 50vw;
  height: 60px;
  font-size: 23px;
  line-height: 27.6px;
  font-family: 'ColfaxWebMedium';
  margin-bottom: 105px;
  align-self: center;
}

//--------------------------//
//-- ACCESS & PURCHASE BTN--//
//-------------------------//
.dca-marketing-access-duration-and-purchase-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin-top: 50px;
  // margin-bottom: 140px;
}

.dca-stickynav-header-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  max-height: fit-content;
  padding: 10px;
  border-bottom: solid 1px #ECEDF1;
}

.dca-marketing-stickynav-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  max-height: fit-content;
  // padding: 10px;

  // display: flex;
  width: 100%;
  // flex-direction: row;
  justify-content: space-between;
}

.dca-marketing-access-duration-and-purchase-button-navbar {
  max-width: 132px;
  max-height: 50px;
  border-radius: 14px;
  border: transparent;
  background-color: #D5FBAB;
  font-family: var(--font-M);
  margin-right: 12px;
  // font-size: 20px;
  // line-height: 24px;
  // margin: 12px 19px;
}

.dca-marketing-stickynav-logo {
  font-size: 14.5px;
  line-height: 26px;
  letter-spacing: .48em;
  font-family: 'ColfaxWebMedium';
  padding: 10px;
  cursor: pointer;
  margin-left: 3vw;
  align-self: center;
}

.dca-marketing-access-duration-and-purchase-button-spacer {
  width: 25px;
}

.dca-marketing-access-duration-and-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dca-marketing-access-duration-text {
  font-family: var(--font-M);
  line-height: 22.93px;
  font-size: 13px;
  letter-spacing: 1.56px;
}

.dca-marketing-access-duration-price {
  font-family: var(--font-M);
  font-size: 21px;
  line-height: 25.2px;
}

.dca-marketing-access-duration-purchase-button {
  // border-radius: 14px;
  border-radius: 10px;
  border: transparent;
  background-color: #D5FBAB;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.dca-marketing-access-duration-purchase-button-nav {
  // border-radius: 15px;
  border-radius: 10px;
  border: transparent;
  background-color: #D5FBAB;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

.dca-marketing-access-duration-purchase-button:hover,
.dca-marketing-access-duration-purchase-button-nav:hover {
  background-color: #D5FBAB
}

.dca-marketing-access-duration-purchase-button-text {
  font-family: var(--font-M);
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  padding: 15px 24px;
  color: #000000;
}

.dca-marketing-access-duration-purchase-button-nav-text {
  font-family: var(--font-M);
  font-size: 15px;
  text-align: center;
  margin: 12px 20px 0px 20px;
  color: #000000;
}

.dca-marketing-access-duration-purchase-button-nav-text-advisor {
  font-family: var(--font-M);
  font-size: 15px;
  text-align: center;
  align-items: center;
  margin: 12px 20px;
  color: #000000;
}

.dca-marketing-access-duration-purchase-button-sign-in-text {
  margin-top: 9px;
  line-height: 20.4px;
  // font-size: 17px;
}

.dca-marketing-access-duration-purchase-button-sign-in-text-modal-trigger {
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
}

.dca-marketing-bullet-points {
  width: 53vw;
  overflow-wrap: break-word;
  font-size: 21px;
  line-height: 43px;
}

.dca-marketing-background-info-trigger {
  font-family: var(--font-M);
  font-size: 17px;
  text-decoration: underline;
  cursor: pointer;
  margin: 11px 0px 140px 0px;
}

//------------------------------//
//---- Specialized Rankings ----//
//------------------------------//
.dca-marketing-specialized-rankings-categories-wrapper {
  display: block;
  margin: 30px auto 100px auto;
  justify-content: center;
}

.dca-marketing-specialized-rankings-categories-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
  max-width: 90vw;
}

.dca-marketing-specialized-rankings-categories-card {
  flex: 1;
  min-width: 0;
  margin: 0px 5vw;
}

.dca-marketing-specialized-rankings-categories-card-title {
  font-family: var(--font-M);
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: 0.12em;

}

.dca-marketing-specialized-rankings-categories-card-list {
  font-family: var(--font-M);
  font-size: 15px;
  line-height: 37px;
}

//--------------------------------------//
//---- ECInsights Purchased Reports ----//
//--------------------------------------//
.dca-marketing-ec-insight-purchased-reports-wrapper {
  margin-top: 65px;
  margin-bottom: 75px;
  font-family: var(--font-M);
}

.dca-marketing-ec-insight-purchased-reports-header {
  font-size: 23px;
  line-height: 27.6px;
}

.dca-marketing-ec-insight-purchased-reports-card {
  display: flex;
  position: relative;
  border-radius: 19px;
  border: var(--marketing-card-border);
  overflow-wrap: break-word;
  margin: 35px 0px;
  max-width: 1030px;
}

.dca-marketing-ec-insight-purchased-reports-card:hover {
  background-color: #CBF999;
  border: var(--marketing-card-border-hover);
  cursor: pointer;
}

.dca-marketing-ec-insight-purchased-reports-card-text-span {
  padding: 58px;
  font-size: 25px;
  line-height: 32.4px;
  // width: 100%;
  overflow-wrap: break-word;
}

.dca-marketing-ec-insight-purchased-reports-card-diamond {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25.94px;
  height: auto;
}

.dca-marketing-page-premium-content-label {
  font-family: var(--font-M);
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: 1.68px;
  margin-top: 60px;
}

.dca-marketing-page-premium-content-label img {
  width: 25.94px;
  height: 29.47px;
}

.dca-marketing-page-premium-content-headline {
  font-family: var(--font-M);
  font-size: 46px;
  line-height: 55.2px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 30px;
}

.dca-marketing-ec-insight-report-text-base {
  font-size: 14px;
  font-family: var(--font-M);
  line-height: 28.2px;
  letter-spacing: 1.68px;
}

.dca-marketing-ec-insight-report-text-base-ecName {
  font-size: 21px;
  font-family: var(--font-M);
  line-height: 12.18px;
  margin-bottom: -20px;
}


//-------------------------//
//---------- FAQ ----------//
//-------------------------//
.dca-marketing-faq-wrapper {
  max-width: 53vw;
  // margin-bottom: 46px;
  display: flex;
  flex-direction: column;
}

.dca-marketing-faq-question {
  font-family: 'ColfaxWebMedium';
  font-size: 21px;
  line-height: 58px;
  // margin-bottom: 50px
  // margin-bottom: 10px;
}

.dca-marketing-faq-answer {
  font-size: 21px;
  line-height: 43px;
}

//-------------------------//
//------ HOVER CARDS ------//
//-------------------------//
.dca-marketing-college-hover-cards-spacer {
  height: 140px;
}

.dca-marketing-hover-cards-component-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0px 66px;
}

.dca-marketing-hover-cards-component-wrapper-ecInsightTrue {
  display: flex;
  flex-direction: column;
}

.dca-marketing-hover-cards-component-wrapper-purchase-true {
  display: flex;
  flex-direction: column;
}

.dca-marketing-hover-cards-title {
  font-size: 23px;
  line-height: 40px;
  font-family: 'ColfaxWebMedium';
  margin-bottom: 38px;
}

.dca-marketing-hover-cards-wrapper {
  display: flex;
  flex-direction: row;
  gap: 42px;
}

.dca-marketing-hover-card {
  border-radius: 19px;
  border: var(--marketing-card-border);
  display: flex;
  flex-direction: column;
  width: 28vw;
}

.dca-marketing-hover-card:hover {
  background-color: #CBF999;
  border: var(--marketing-card-border-hover);
  cursor: pointer;
}

.dca-marketing-hover-card-header {
  font-size: 14px;
  line-height: 28.2px;
  letter-spacing: 1.68px;
  font-family: 'ColfaxWebMedium';
  margin-bottom: 2px;
}

.dca-marketing-hover-card-body {
  font-size: 21px;
  line-height: 36px;
  font-family: 'ColfaxWebMedium';
  // width: 88%;
  overflow-wrap: break-word;
  // margin: 0px 0px 45px 37px;
}

.dca-marketing-hover-card-diamond {
  width: 35.74px;
  height: 40.59px;
  margin-bottom: 12px;
}

.dca-marketing-hover-card-inner-wrapper {
  // margin: 25px 37px 37px 37px;
  // #677 - reduce bottom padding by ~13px
  margin: 25px 37px 24px 37px;
}


//---------------------------------------//
//--- COVER STORY CTA AND EMAIL LINK ---//
//--------------------------------------//
.dca-marketing-page-ec-insights-cover-story-link {
  font-family: var(--font-M);
  font-size: 20px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
  margin: 180px 64px 0px 0px;
  align-self: end;
}

.dca-marketing-page-email-cta {
  font-size: 19px;
  line-height: 22.8px;
  margin: 19px 64px 0px 0px;
  align-self: end;
}

.dca-marketing-page-email-link {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}

//-------------------------//
//-------- IMAGES ---------//
//-------------------------//
.dca-marketing-image-wrapper,
.dca-marketing-image-wrapper-A,
.dca-marketing-image-wrapper-B,
.dca-marketing-image-wrapper-C,
.dca-marketing-image-wrapper-D {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dca-marketing-image-wrapper-A {
  max-width: 100vw;
}

.dca-marketing-image-wrapper-B {
  max-width: 48vw;
}

.dca-marketing-image-wrapper-C {
  max-width: 281px;
}

.dca-marketing-image-wrapper-D {
  max-width: 55vw;
}

.dca-marketing-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

.dca-marketing-temporary-img {
  height: 400px;
  width: 500px;
  border: solid gray 1px;
  background-color: rgb(228, 228, 228);
  margin-bottom: 92px;
}

// .dca-marketing-image {
//   border-radius: 16px;
//   border: 1px solid #EDEDED;
//   width: 604px;
//   height: 615px;
//   display: block;
//   margin: 0 auto;
// }

.dca-marketing-image-A {
  width: 616px;
  height: 704px;
  border: 1px solid #EDEDED;
  border-radius: 16px;
  display: flex;
  align-self: center;
}

.dca-marketing-image-B {
  width: 763px;
  height: 564px;
  border: 1px solid #EDEDED;
  border-radius: 20px;
  display: flex;
  align-self: center
}

.dca-marketing-image-C {
  width: 281px;
  height: 216px;
  // border: 1px solid #EDEDED;
  // border-radius: 20px;
  margin-bottom: var(--block-bottom-margin)
}

.dca-marketing-page-college-logo-img {
  height: 49px;
  width: 49px;
  margin-right: 10px;
}

#dca-mobile-specialized-rankings-table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: '55vw' !important;
  max-width: '65vw' !important;
  margin-top: 30px;
  margin-left: 30px;
  padding-right: 26px;
  padding-left: 0px;
}

#dca-college-rdSpend-header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#dca-mobile-crime-safety-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;
}

#dca-college-detail-crime-charts-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

#dca-college-detail-crime-chart {
  width: 50%;
}

.dca-rdspend-modal {
  font-family: var(--font-M);
  min-width: fit-content;
  max-width: 75vw;
  min-height: 85vh;
  max-height: 85vh;
  padding: 60px 100px 60px 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}