@import url('../../style.react.scss');

:root {
  --marketing-card-border: 1px solid #DBDFE9;
  --marketing-card-border-hover: 1px solid #CBF999;

  --marketing-card-margin-left: 37px
}

@media only screen and (max-width: 768px) {
  .dca-marketing-paragraph {
    align-self: center;
    margin: 10vh 0;
    width: 90vw;
    line-height: 33px;
    font-size: 18px;
  }

  .dca-marketing-qa {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 20px 0;
    max-width: 90vw;
    line-height: 33px;
    font-size: 18px;
  }

  .dca-marketing-page-ec-insights-purchase-true-wrapper {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

  .dca-marketing-page-college-logo-and-name-wrapper {
    width: 100vw;
    align-self: flex-start;
  }

  #rdSpend_inner_card_content_margin {
    max-width: 90vw;
  }

  #dca-college-rdSpend-header {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  #dca-mobile-crime-safety-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px;
    align-items: center;
  }

  #dca-college-detail-crime-charts-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 30px;
  }

  #dca-college-detail-crime-chart {
    width: 95%;
  }

  .dca-marketing-page-college-logo-and-name {
    font-family: var(--font-M);
    font-size: 18px;
    line-height: 22.6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
  }

  #dca-marketing-page-college-logo-and-name-specialized-rankings {
    width: 80vw !important;
    max-width: 80vw !important;
    margin-left: 0px !important;
    display: flex;
    align-self: center;

    // width: 80vw !important;
    // max-width: 80vw !important;
    // margin-left: 0px;
    // padding-left: 0px !important;
  }

  .dca-marketing-disclaimer-text {
    font-size: 14px;
    line-height: 22px;
    color: #7F7F7F;
    margin-top: 4px;
    margin-bottom: 60px;
    max-width: 80vw;
    overflow-wrap: break-word;
  }

  .dca-marketing-connect-email-cta {
    font-size: 16px;
    line-height: 33px;

    margin-top: 8px;
    margin-bottom: 40px;
  }

  //-------------------------//
  //-------- SELECT ---------//
  //-------------------------//
  .dca-marketing-page-no-select-text {
    font-size: 18px;
    font-family: var(--font-M);
    margin-top: 164px;
    max-width: 80vw;
    text-align: center;
  }

  .dca-marketing-ec-select-with-insight,
  .dca-marketing-ec-select {
    width: 364px;
    font-size: 19px;
    line-height: 22.8px;
    font-family: 'ColfaxWebMedium';
  }

  //////
  /// College Select
  .dca-marketing-college-select {
    width: 364px;
    height: 60px;
    font-size: 19px;
    line-height: 22.8px;
    font-family: 'ColfaxWebMedium';
    margin-bottom: 105px;
    align-self: center;
  }

  //--------------------------------------//
  //---- ECInsights Purchased Reports ----//
  //--------------------------------------//
  .dca-marketing-ec-insight-purchased-reports-header {
    font-size: 19px;
    line-height: 33px;
    font-family: var(--font-M);
  }

  .dca-marketing-ec-insight-purchased-reports-card {
    display: flex;
    position: relative;
    border-radius: 19px;
    border: var(--marketing-card-border);
    overflow-wrap: break-word;
    margin: 28px 0px;
    max-width: 90vw;
  }

  .dca-marketing-ec-insight-purchased-reports-card-text-span {
    padding: 28px 25px;
    font-size: 18px;
    line-height: 35px;
    width: 90%;
    overflow-wrap: break-word;
  }

  .dca-marketing-ec-insight-purchased-reports-card-diamond {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25.94px;
    height: auto;
  }

  .dca-marketing-ec-insight-report-text-base {
    font-size: 12.5px;
    font-family: var(--font-M);
    line-height: 28.2px;
    letter-spacing: 1.68px;
  }

  .dca-marketing-page-premium-content-label {
    font-family: var(--font-M);
    font-size: 14px;
    line-height: 24.7px;
    letter-spacing: 1.68px;
    margin-top: 60px;
  }

  .dca-marketing-page-premium-content-label img {
    width: 25.94px;
    height: 29.47px;
  }

  .dca-marketing-page-premium-content-headline {
    font-family: var(--font-M);
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 30px;
  }

  .dca-marketing-page-headline {
    font-family: 'ColfaxWebMedium';
    text-align: center;
    font-size: 22px;
    line-height: 33px;
    // margin-top: 50px;
    margin-top: 25px;
    margin-bottom: 10px;
    // width: 65vw;
    max-width: 350px;
    overflow-wrap: break-word;
  }

  #dca-marketing-page-headline-ec-insights {
    width: 90vw;
  }

  .dca-marketing-sub-headline,
  .dca-marketing-sub-headline-ec {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    overflow-wrap: break-word;
    max-width: 350px;
    margin-bottom: 48px;
  }

  .dca-marketing-pt-wrapper {
    // max-width: 53vw;
    max-width: 350px;
    margin-bottom: 20px;
  }

  .dca-marketing-pt-wrapper-B {
    // max-width: 53vw;
    max-width: 350px;
  }

  .dca-marketing-pt-header {
    font-family: 'ColfaxWebMedium';
    font-size: 19px;
    line-height: 33px;
  }

  .dca-marketing-pt-body {
    font-size: 18px;
    line-height: 33px;
  }

  .dca-marketing-standalone-text {
    font-size: 18px;
    line-height: 33px;
    // max-width: 53vw;
    max-width: 350px;
    margin-bottom: 83px;
  }

  //-------------------------//
  //---------- FAQ ----------//
  //-------------------------//
  .dca-marketing-faq-wrapper {
    max-width: 90vw;
    // margin-bottom: 46px;
    display: flex;
    flex-direction: column;
  }

  .dca-marketing-faq-question {
    font-family: 'ColfaxWebMedium';
    font-size: 18px;
    line-height: 33px;
  }

  .dca-marketing-faq-answer {
    font-size: 18px;
    line-height: 33px;
  }


  //-------------------------//
  //------ HOVER CARDS ------//
  //-------------------------//
  .dca-marketing-hover-cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 42px;
  }

  .dca-marketing-hover-card-inner-wrapper {
    margin: 25px 32px 24px 32px;
    // margin: 12.5px 8px 30px 8px;
  }

  .dca-marketing-hover-card {
    border-radius: 19px;
    border: var(--marketing-card-border);
    display: flex;
    flex-direction: column;
    width: 394px;
    max-width: 90vw;
  }

  .dca-marketing-hover-card-diamond {
    width: 29.14px;
    height: 30.36px;
    margin-bottom: 12px;
    // margin-left: var(--marketing-card-margin-left)
  }

  .dca-marketing-hover-cards-title {
    font-size: 19px;
    line-height: 40px;
    font-family: 'ColfaxWebMedium';
    margin-bottom: 38px;
    text-align: center;
  }

  .dca-marketing-hover-card-header {
    font-size: 14px;
    line-height: 28.2px;
    letter-spacing: 1.68px;
    font-family: 'ColfaxWebMedium';
    // margin-left: var(--marketing-card-margin-left)
  }

  .dca-marketing-hover-card-body {
    font-size: 18px;
    line-height: 36px;
    font-family: 'ColfaxWebMedium';
    width: 88%;
    overflow-wrap: break-word;
    // margin: 0px 0px 0px var(--marketing-card-margin-left);
  }

  .dca-marketing-bullet-points {
    max-width: 75vw;
    width: 75vw;
    overflow-wrap: break-word;
    font-size: 18px;
    line-height: 33px;
  }

  //-------------------------//
  //------ TESTIMONIAL ------//
  //-------------------------//
  .dca-marketing-testimonial-wrapper {
    margin-bottom: 138px;
    display: flex;
    flex-direction: column;
    width: 80vw;
    align-items: center;
  }

  .dca-marketing-testimonial-text {
    min-width: 80vw;
    margin: 70px 0 30px 0;
    font-size: 18px;
    line-height: 33px;
    text-align: center;
  }

  .dca-marketing-image-wrapper,
  .dca-marketing-image-wrapper-A,
  .dca-marketing-image-wrapper-B,
  .dca-marketing-image-wrapper-D {
    max-width: 90vw !important;
  }

  .dca-marketing-image-wrapper-C {
    max-width: 50vw !important;
  }

  #TIB-25 {
    margin-bottom: 25px;
  }

  #TIB-35 {
    margin-bottom: 35px;
  }

  #TIB-40 {
    margin-bottom: 40px;
  }

  #TIB-120 {
    margin-bottom: 70px;
  }

  #TIB-140 {
    margin-bottom: 70px;
  }

  #TIB-150 {
    margin-bottom: 70px;
  }

  #TIB-175 {
    margin-bottom: 70px;
  }

  #dca-mobile-specialized-rankings-table {
    width: 80vw !important;
    max-width: 80vw !important;
    // margin-left: 0px;
    padding-left: 0px !important;
  }

  .dca-marketing-ec-insight-report-text-base-ecName {
    font-size: 18px;
    line-height: 21.18px;
    margin-bottom: -20px;
    text-align: center;
    width: 95vw;
    overflow-wrap: break-word;
  }

  .dca-rdspend-modal {
    font-family: var(--font-M);
    min-width: 85vw;
    width: 85vw;
    max-width: 85vw;
    min-height: 85vh;
    max-height: 85vh;
    padding: 30px 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
  }

  .dca-college-premium-mobile-modal {
    font-family: var(--font-M);
    min-width: 85vw !important;
    width: 85vw !important;
    max-width: 85vw !important;
    min-height: 85vh !important;
    max-height: 85vh !important;
    padding: 30px 50px !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    overflow: auto !important;
  }

  #dca-rdspend-modal-content {
    margin: 0 auto;
    font-size: 0.8rem;
  }

  #dca-mobile-modal-overflow {
    overflow: auto;
  }

}