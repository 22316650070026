.dca-advisor-card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 30px;
  width: 48%;
  border-radius: 20px;
  border: 1px solid #ededed;
  position: relative;
}

.dca-advisor-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.dca-advisor-search-card-image-wrapper {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 33%;
  padding-top: 68px;
}

.dca-advisor-search-card-image {
  height: 120px;
  width: 120px;
  border-radius: 12px;
}

.dca-advisor-search-card-nameField {
  font-size: 1.25rem;
  font-family: 'ColfaxWebMedium';
}

.dca-advisor-detail-tabs {
  height: fit-content;
  cursor: pointer;
  border: 1px rgba(219, 223, 233, 0.4) dashed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: inline-block;
  margin-right: 8px;
  // font-family: 'ColfaxWebMedium';
}

.dca-advisor-detail-content-title {
  font-family: 'ColfaxWebMedium';
}

.dca-advisor-detail-advisor-name {
  font-family: 'ColfaxWebMedium';
  font-size: 30px;
}

.dca-connect-button-card-v2,
.dca-view-advisor-button {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  // height: 100%;
  // min-height: 43px;
  // max-height: 50px;
  height: 50px;
  width: 90%;
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  border-radius: 13px;
  border: transparent;
  font-family: var(--font-M);
  font-size: 17px;
  text-align: center;
  // margin-bottom: 18px;
}

.dca-view-advisor-button {
  background: #FAFAFA;
  margin-top: 26px;
  margin-bottom: 18px;
}

.dca-connect-button-card-v2 {
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  margin-bottom: 30px;
}

.dca-connect-button-card-detail {
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  font-family: 'ColfaxWebMedium';
  padding: calc(0.7rem + 1px) calc(1.25rem + 1px) !important;
  border-radius: 0.625rem !important;
}

.dca-advisor-detail-avatar {
  height: 200px;
  padding: 30px;
  border-radius: 40px;
}

// .dca-row-to-column-350 {
//   display: flex;
//   flex-direction: row;
// }

@media only screen and (max-width: 768px) {
  .dca-connect-button-card-detail {
    height: 40px;
    // width: calc(100% - 40px);
    width: 150px;
    align-self: center;
    margin-bottom: 10px;
  }

  .dca-advisor-detail-avatar {
    height: 100px;
    width: 100px;
    padding: 0px;
    border-radius: 10px;
    margin-left: 15px;
  }
}

// @media only screen and (max-width: 350px) {
//   .dca-advisor-detail-avatar {
//     align-self: center;
//   }

//   .dca-row-to-column-350 {
//     flex-direction: column;
//   }
// }