// Set global variables
:root {
  // --fresh-green: rgba(218, 248, 185, 1);
  --fresh-green: #CBF999;
  --selectedTabGreen: #DAF8B9;
  --gray: rgba(250, 250, 250, 1);
  --header-bttn-gray: rgb(242, 242, 242);
  --gray-bttn: #fafafa;
  --gray-bttn-radius: 10px;
  --gray-bttn-border: transparent;
  --unselectedTabGray: #FFFFFF;
  --header-bttn-padding: 10px 18px;
  --font-M: 'ColfaxWebMedium'
}

/* Colfax Web Regular */
@font-face {
  font-family: 'ColfaxWebRegular';
  src: url('../../../../public/webfonts/ColfaxWebMedium.eot');
  src: url('../../../../public/webfonts/ColfaxWebRegular.eot?#iefix') format('embedded-opentype'),
    url('../../../../public/webfonts/ColfaxWebRegular.woff2') format('woff2'),
    url('../../../../public/webfonts/ColfaxWebRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Colfax Web Medium */
@font-face {
  font-family: 'ColfaxWebMedium';
  src: url('../../../../public/webfonts/ColfaxWebMedium.eot');
  src: url('../../../../public/webfonts/ColfaxWebMedium.eot?#iefix') format('embedded-opentype'),
    url('../../../../public/webfonts/ColfaxWebMedium.woff2') format('woff2'),
    url('../../../../public/webfonts/ColfaxWebMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#root {
  display: contents;
}

body {
  font-family: 'ColfaxWebRegular' !important;
}

.apexcharts-text tspan,
.apexcharts-legend-text,
.apexcharts-data-labels text {
  font-family: 'ColfaxWebRegular' !important;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.fwImport {
  font-weight: 50 !important;
}

.dca-word-break {
  word-break: break-word;
  white-space: normal;
}

.dca-custom-break-word-advisor {
  white-space: nowrap;
}

.dca-italic-text {
  font-style: italic;
}

.dca-chart-title-margin {
  padding-left: 3.5rem
}

.dca-font-R {
  font-family: 'ColfaxWebRegular';
}

.dca-font-M {
  font-family: 'ColfaxWebMedium';
}

.dca-ordered-item {
  display: inline-block;
  margin-left: 50px;
  font-size: 21px;
  line-height: 1.75em;
  align-self: self-start;
}

.dca-breadcrumb-link {
  color: rgb(00, 00, 00);
  text-decoration: underline;
  font-family: 'ColfaxWebRegular';
}

.breadcrumb .breadcrumb-item:after {
  color: rgb(00, 00, 00);
}

.dca-gray-button {
  background: var(--gray-bttn);
  border-radius: var(--gray-bttn-radius);
  border: var(--gray-bttn-border);
  font-family: 'ColfaxWebMedium';
}

.dca-green-button {
  border-radius: var(--gray-bttn-radius);
  border: transparent;
  font-family: 'ColfaxWebMedium';
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
}

.dca-premium-data-button-color {
  // background: linear-gradient(#D5FBAB 0%, #B7FFB5 100%)
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%)
}

.dca-checkbox {
  border: transparent;
  background-color: #F9F9F9;
  vertical-align: middle;
}

.dca-aside-header {
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: .12em;
  font-family: 'ColfaxWebMedium';
}

.dca-select {
  border: transparent;
  background-color: #F9F9F9
}

.dca-Calendar .p-inputtext {
  border: transparent;
  background-color: #F9F9F9;
  font-family: 'ColfaxWebMedium';
}

.dca-save-button-card {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 10px;
  font-family: 'ColfaxWebMedium';
}

.dca-advisor-connect-button-card {
  position: absolute;
  top: 15px;
  right: 105px;
  border-radius: 10px;
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  font-family: 'ColfaxWebMedium';
}

.dca-advisor-connect-button-inline {
  margin-right: 15px;
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  white-space: nowrap;
  border-radius: 10px;
  font-family: 'ColfaxWebMedium';
}

// .dca-connect-button-card {
//   position: absolute;
//   top: 20px;
//   right: 15px;
//   background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
//   font-family: 'ColfaxWebMedium';
//   padding: calc(0.7rem + 1px) calc(1.25rem + 1px) !important;
//   border-radius: 0.625rem !important;
// }

.dca-college-details-admission-considerations-trend-icon {
  width: 10px;
  height: 8px;
  vertical-align: middle;
}

#dca-mobile-college-details-admission-considerations-legend-icon-wrapper {
  width: '18px'
}

.dca-college-details-admission-considerations-trend-icon-B {
  width: 14px;
  height: 8px;
  vertical-align: middle;
}

.dca-connect-button-card {
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  font-family: 'ColfaxWebMedium';
  font-size: 17px;
  // border-radius: 0.625rem !important;
  border-radius: 13px !important;
  padding: calc(0.7rem + 1px) calc(1.25rem + 1px) !important;
}

.dca-connect-with-advisor-inside-scoop {
  background: var(--fresh-green);
  font-family: 'ColfaxWebMedium';
  padding: calc(0.7rem + 1px) calc(1.25rem + 1px) !important;
  border-radius: 0.625rem !important;
}

.dca-connect-with-advisor-inside-scoop:hover {
  background: var(--fresh-green);
}

.dca-save-button-inline {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  font-family: 'ColfaxWebMedium';
}

.dca-tooltip>.tooltip-inner {
  background-color: rgb(30, 33, 41);
  color: white;
  text-align: left;
}

.dca-apexcharts-tooltip {
  background-color: rgb(30, 33, 41);
  color: white;
  text-align: left;
  padding: 10px 12px 0 12px;
}

.dca-test-score-modal-recommendation-pill {
  max-height: fit-content;
  min-width: fit-content;
  border-radius: 20px;
  border: transparent;
  padding: 5px 10px;
  box-sizing: border-box;
  font-family: 'ColfaxWebMedium';
  font-size: 18px;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  margin-top: 20px
}

.dca-test-score-modal-enter-scores-pill {
  background: linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%);
  max-height: fit-content;
  min-width: fit-content;
  border-radius: 18px;
  border: transparent;
  padding: 5px 10px;
  box-sizing: border-box;
  font-family: 'ColfaxWebMedium';
  white-space: nowrap;
  font-size: 20px;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  margin-top: 20px
}

.dca-test-score-modal-why-text {
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 18px;
  font-family: 'ColfaxWebMedium';
  text-decoration: underline;
  align-self: end;
  color: #3c77ff
}

.dca-chart-title-wrapper {
  --chart-wrapper-width: 30%;

  width: var(--chart-wrapper-width);
  position: relative;
}

.dca-chart-title-position {
  position: absolute;
  top: -20px;
  left: 18px;
  // font-weight: bold;
}

.dca-college-details-total-row {
  text-align: center;
  background: #F9F9F9;
}

.dca-college-detail-major-table-sort-carat,
.dca-college-detail-major-table-sort-carat-inactive,
.dca-college-detail-major-table-sort-carat-desc {
  height: 10.35px;
  width: 8px;
}

.dca-college-detail-major-table-sort-carat-desc {
  transform: rotate(180deg);
}

.dca-college-search-sort-carat-b {
  transform: rotate(180deg);
}

.dca-loading-indicator-image {
  width: 70%;
  height: 70%;
  object-fit: cover;
  align-self: center;
}

.dca-college-detail-crime-table-column-header {
  font-size: 12px;
  line-height: 24.7px;
  letter-spacing: .12em;
}

.dca-crime-safety-header-item {
  font-size: 12px;
  line-height: 24.7px;
  letter-spacing: .12em;
  font-family: 'ColfaxWebMedium';
  padding: 10px;
  color: black
}

.dca-header-spacing {
  font-size: 12px;
  line-height: 24.7px;
  letter-spacing: .12em;
  font-family: 'ColfaxWebMedium';
  padding: 10px;
  color: black
}

.dca-header-spacing-B {
  font-size: 12px;
  line-height: 24.7px;
  letter-spacing: .12em;
  font-family: 'ColfaxWebMedium';
  color: black
}

// College Detail Specialized Ranking 
// Value return
.dca-college-detail-SR-value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 22px;
}

#screen_width_alert_modal {
  display: none;
}

#screen_width_alert_modal.show {
  display: block;
  font-size: 21px;
  font-family: 'ColfaxWebMedium';
}

#screen_width_alert_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.dca-close-modal-icon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

#dcaPurchasePreview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#dcaPurchasePreviewPrice {
  margin-left: 18px;
}

#dcaPurchasePreviewButton {
  font-weight: bold;
  align-self: flex-end;
}

////////////////////////////////
//////// Mobile styling ////////
////////////////////////////////
@media only screen and (max-width: 767px) {

  // Apply black font color to buttons, prevent apple override and passing '-apple-system-blue:
  button:not(:disabled) {
    color: black
  }

  .dca-test-score-modal-enter-scores-pill {
    font-size: 14px;
  }

  // .dca-test-score-modal-recommendation-pill {
  //   font-size: 18px;
  // }

  .dca-chart-title-wrapper {
    --chart-wrapper-width: 100%;

    width: var(--chart-wrapper-width);
    position: relative;
  }

  #terms-of-service-font-mobile {
    font-size: 12px;
    font-family: var(--font-M);
  }

  .dca-close-modal-icon {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .dca-aside-header {
    font-size: 12px;
    line-height: 176.4%;
    letter-spacing: calc(0.12 * 12px);
    font-family: 'ColfaxWebMedium';
  }

  // .breadcrumb {
  //   cursor: pointer;
  //   position: fixed;
  //   top: 20px;
  //   right: 20px;
  //   z-index: 1000;
  // }

  .dca-premium-close-modal-icon-hide {
    display: none;
  }

  .dca-ordered-item {
    display: inline-block;
    margin-left: 25px;
    font-size: 12px;
    line-height: 1.75em;
    align-self: self-start;
  }

  .dca-save-button-card-mobile {
    position: absolute;
    top: 15px;
    right: 15px;
    border: transparent;
    background: transparent;
    border-radius: 10px;
    font-family: 'ColfaxWebMedium';
  }

  .dca-save-button-card-mobile i {
    font-size: 20px;
  }

  .dca-400-fc {
    height: 90px
  }

  body {
    font-family: 'ColfaxWebRegular' !important;
    margin: 0 auto !important;
  }

  // .dca-advisor-connect-button-card {
  //   max-width: 200px;
  // }

}

@media only screen and (max-width: 410px) {
  .dca-400-fc {
    height: 135px
  }

  #dcaPurchasePreview {
    display: flex;
    flex-direction: column;
  }

  #dcaPurchasePreviewPrice {
    margin-left: 0px;
    margin-top: 10px;
  }

  #dcaPurchasePreviewButton {
    font-weight: bold;
    align-self: center;
  }
}

@media only screen and (max-width: 350px) {
  .dca-custom-break-word-advisor {
    white-space: wrap;
  }

  .dca-400-fc {
    height: 185px
  }
}