:root {
  --font-size-section-title: 30px;

  --font-size-mobile-section-title: 18px;
}

.dca-userProfile-form-title,
.dca-userProfile-form-field,
.dca-userProfile-interest-field,
.dca-userProfile-save-changes-button,
.dca-userProfile-discard-changes-button {
  font-family: 'ColfaxWebMedium';
}

.dca-userProfile-form-card {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  border: solid 1px #F1F1F2;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #ede6e6;
}

.dca-userProfile-form-title {
  font-size: var(--font-size-section-title);
  padding: 30px 0px 15px 35px;
}

.dca-userProfile-form-field-wrapper {
  display: flex;
  flex-direction: row;
}

.dca-userProfile-form-field-column {
  display: flex;
  flex-direction: column;
  min-width: 40%;
  width: 40%;
  max-width: 40%;
  margin-left: 10px;
}

.dca-userProfile-form-field {
  font-size: 19px;
  padding: 20px;
  display: grid;
  align-items: center;
  min-height: fit-content;
  height: 30%;
}

.dca-userProfile-interest-field {
  font-size: 16px;
  margin: 10px 35px 10px 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  min-height: fit-content;
}

.dca-userProfile-save-changes-button,
.dca-userProfile-discard-changes-button {
  font-size: 16px;
  max-height: fit-content;
  min-width: fit-content;
  border-radius: 10px;
  border: transparent;
  padding: 10px 20px;
  margin: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  align-self: end;
  text-align: center;
  color: black
}

.dca-userProfile-save-changes-button {
  background: linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%);
}

.dca-userProfile-discard-changes-button {
  background: #FAFAFA;
}

.dca-userProfile-tabs {
  // height: 30px;
  // cursor: pointer;
  // border-radius: 50%;
  // display: inline-block;
  // margin-bottom: 5px;

  height: fit-content;
  cursor: pointer;
  border: 1px rgba(219, 223, 233, 0.4) dashed;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: inline-block;
}

////////////////////////////////
//////// Mobile styling ////////
////////////////////////////////
@media only screen and (max-width: 767px) {}