@import url('../style.react.scss');

:root {
  --header-item-padding-top-bottom: calc(0.775rem + 1px);
  --header-item-padding-left: (1.5rem + 1px);

  --header-item-padding-left-midrange: (0.8rem + 1px)
}

.dca-header-signin-signup-spacer {
  color: black;
  // margin: 0 26px
}

.dca-header-dashboard-alert {
  background: #DAF8B9;
  font-size: 18px;
  line-height: 170.4%;
  // height: 120px;
  border-radius: 6px;
  border: transparent;
  z-index: 9999;
  max-width: 238px;
  color: black;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.dca-header-dashboard-alert-pointer {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid #DAF8B9;
}

.dca-stickyHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-height: fit-content;
  position: fixed;
  top: 0;
  padding: 10px;
  border-bottom: solid 1px #ECEDF1;
  animation: fadeIn ease 1s;
  background: #fff;
  z-index: 9999;
}

.dca-header-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  max-height: fit-content;
  padding: 10px 38px;
  border-bottom: solid 1px #ECEDF1;
}

.dca-header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
}

.dca-header-sign-in-up {
  font-family: 'ColfaxWebMedium';
  align-self: center;
  font-size: 12px;
  line-height: 176.4%;
  letter-spacing: calc(0.12*12px);
  color: black;
  cursor: pointer;
}

.dca-header-dropdowns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dca-header-dropdown-menu {
  margin-left: 3vw;
  margin-right: 3vw;
}

.dca-header-sidebar-logo {
  font-size: 14.5px;
  line-height: 26px;
  letter-spacing: .48em;
  font-family: 'ColfaxWebMedium';
  padding: 10px;
  cursor: pointer;
}

.dca-header-logo {
  font-size: 14.5px;
  line-height: 26px;
  letter-spacing: .48em;
  font-family: 'ColfaxWebMedium';
  padding: 10px 0;
  cursor: pointer;
  // margin-left: 3vw;
  // margin-left: 15px;
}

.dca-header-item {
  font-size: 12px;
  line-height: 24.7px;
  letter-spacing: .12em;
  font-family: 'ColfaxWebMedium';
  padding: 10px;
  cursor: pointer;
  color: black
}

.dca-header-sidebar-item {
  font-family: 'ColfaxWebMedium';
  font-size: 11px;
  line-height: 176.4%;
  letter-spacing: calc(0.12*11px);
  margin: 10px 0px;
  display: block;
  padding-left: 8px;
}

.dca-header-overview-block {
  padding: 10px 20px;
}

.dca-header-tools-resources-block {
  display: flex;
  flex-direction: row;
  // gap: 40px;
  padding: 10px 20px;
  width: 100%;

  max-width: 100%;
  overflow: hidden;
}

.dca-header-dropdown-item {
  font-size: 17px;
  color: black;
}

.dca-header-dropdown-item-b {
  display: block;
  font-size: 17px;
  margin-left: 18px;
  padding-right: 0 !important;
  color: black;

  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dca-header-tools-resources-colleges-items {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  margin-right: 15px;
}

.dca-header-tools-resources-other-items {
  display: flex;
  flex-direction: column;

  max-width: 100%;
  overflow: hidden;
  width: 100%;
}

.dca-header-group-header {
  font-family: 'ColfaxWebMedium';
  font-size: 18px;
  // padding-left: 0 !important;
}

.dca-header-group-header:hover {
  background: transparent;
  cursor: default;
}

.dca-group-header-clickable {
  font-family: 'ColfaxWebMedium';
  font-size: 18px;
  color: black;
  // padding-left: 0 !important;
}

#dca-header-blog-item {
  padding: 13px 20px;
  // margin-left: 32.550px;
}

.dca-header-dropdown-item:hover,
.dca-header-dropdown-item-b:hover,
.dca-group-header-clickable:hover {
  background: #DAF8B9
}

.dca-header-signup-bttn {
  cursor: pointer;
  color: black;
  margin-left: 12px;
  background: var(--selectedTabGreen);
  border-radius: 10px;
  border: transparent;
  padding: var(--header-bttn-padding)
}

.dca-header-signin-bttn {
  cursor: pointer;
  color: black;
  padding: var(--header-bttn-padding);
}

.dca-header-signout-bttn {
  color: black;
  background: var(--selectedTabGreen);
  border-radius: 10px;
  border: transparent;
  padding: var(--header-bttn-padding);
}

//#987
.dca-header-my-dashboard.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: var(--header-item-padding-top-bottom) 0 var(--header-item-padding-top-bottom) calc(2 * var(--header-item-padding-left));
}

.dca-header-tools-resources.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: var(--header-item-padding-top-bottom) 0 var(--header-item-padding-top-bottom) calc(3 * var(--header-item-padding-left));
}

.dca-header-blog.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: var(--header-item-padding-top-bottom) calc(3 * var(--header-item-padding-left));
}

.dca-header-signup-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: var(--header-item-padding-top-bottom) calc(var(--header-item-padding-left)) var(--header-item-padding-top-bottom) 0;
}

.dca-header-signin-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding-right: 0;
}

#dropdown-overview {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important
}

#dropdown-overview::after {
  content: none;
}

#dropdown-tools-resources {
  background-color: transparent !important;
  border: none !important;
  color: inherit !important
}

#dropdown-tools-resources::after {
  content: none;
}

.dca-header-menu-toggle {
  visibility: hidden;
  display: none;
}

.dca-search-aside {
  visibility: hidden;
  display: none;
}

.dca-header-sidebar-arrow-up-right,
.dca-header-sidebar-arrow-up-right-mydashboard {
  margin-left: -8px;
  padding-bottom: 4px;
  vertical-align: middle;
  object-fit: contain;
}

.dca-header-sidebar-arrow-up-right {
  height: 32px;
  width: 32px;
}

.dca-header-sidebar-arrow-up-right-mydashboard {
  height: 21px;
  width: 21px;
  margin-left: 2px;
  padding-bottom: 2px;
  transform: scale(1.8);
}

#dca-signin-signup-mobile-aligner {
  display: flex;
  margin-left: auto;
  max-width: 100%;
}

@media only screen and (min-width: 767px) and (max-width: 880px) {

  //#987
  .dca-header-my-dashboard.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: var(--header-item-padding-top-bottom) 0 var(--header-item-padding-top-bottom) calc(2 * var(--header-item-padding-left-midrange));
  }

  .dca-header-tools-resources.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: var(--header-item-padding-top-bottom) 0 var(--header-item-padding-top-bottom) calc(3 * var(--header-item-padding-left-midrange));
  }

  .dca-header-blog.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: var(--header-item-padding-top-bottom) calc(3 * var(--header-item-padding-left-midrange));
  }

  .dca-header-signup-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: var(--header-item-padding-top-bottom) calc(var(--header-item-padding-left-midrange)) var(--header-item-padding-top-bottom) 0;
  }

  .dca-header-signin-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding-left: calc(var(--header-item-padding-left-midrange));
    padding-right: 0;
  }
}

//////// Mobile styling ////////
@media only screen and (max-width: 767px) {

  .dca-header-signup-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: var(--header-item-padding-top-bottom) calc(var(--header-item-padding-left));
  }

  #kt_header_menu_mobile_toggle {
    margin-right: 12px;
    margin-left: max(0px, calc(5vw - 12px));
  }

  .dca-header-wrapper {
    display: flex;
    flex-direction: row;
    // padding: 10px 19px;
    padding: 10px 19px 10px 0px;
    justify-content: space-between;
  }

  .dca-header-menu-toggle {
    visibility: visible;
    display: block;
  }

  .dca-search-aside {
    visibility: visible;
    display: block;
  }

  .dca-header-sidebar-title {
    border-bottom: 2px solid var(--gray);
  }

  .dca-header-container {
    visibility: hidden;
    display: none;
  }

  .dca-header-sidebar-navgroup {
    margin-bottom: 15px;
  }

  .dca-header-sidebar-navitem {
    // margin: 8px 0px;
    display: block;
    font-family: 'ColfaxWebMedium';
    font-size: 16px;
    line-height: 34px;
    text-wrap: nowrap;
    margin: 5px 0px;
    width: fit-content;
    padding-left: 8px;
    // width: 75%;
  }

  .dca-header-sidebar-navitem-b {
    display: block;
    font-family: 'ColfaxWebMedium';
    font-size: 16px;
    line-height: 34px;
    text-wrap: wrap;
    margin: 5px 0px 0px 45px;
    width: fit-content;
  }

  .dca-header-sidebar-navitem,
  .dca-header-sidebar-navitem-b {
    cursor: pointer;
  }

  .dca-header-sidebar-blog {
    display: block;
    color: black;
    font-family: 'ColfaxWebMedium';
    font-size: 16px;
    line-height: 34px;
    text-wrap: wrap;
    padding: 20px 0px 20px 8px;
  }

}

@media only screen and (max-width: 430px) {
  .dca-header-signup-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding: var(--header-item-padding-top-bottom) 10px;
  }

  .dca-header-signin-link.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    padding-left: 10px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 375px) {

  #kt_header_menu_mobile_toggle {
    margin-right: max(0px, calc(5vw - 12px));
    margin-left: max(0px, calc(5vw - 12px));
  }

  .dca-header-sign-in-up {
    font-size: 11px;
  }

  .dca-header-logo {
    font-size: 12.5px;
    padding-left: 10px;
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}