// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

:root {
  --dca-marginLeft: -35px;
}

.dca-home {
  margin-left: var(--dca-marginLeft);
}

//////////////////////////
// Home header / banner //
//////////////////////////

.dca-home-headline-container {
  height: 655px;
  overflow: hidden
}

.dca-home-header-wrapper {
  display: flex;
  flex-direction: column;
  max-width: min-content;
  position: absolute;
  top: 14%;
  left: 8%;
  border-radius: 34px;
  background: white;
  padding: 30px 50px 30px 50px;
}

.dca-headline-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 28%;
  margin-left: 0px
}

.dca-home-header-1 {
  font-size: 26px;
  font-family: 'ColfaxWebMedium';
  text-wrap: nowrap;
  width: fit-content;
}

.dca-home-header-2 {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 900;
  // font-family: 'ColfaxWebMedium';
  text-wrap: wrap;
}

//////////////////////////
// Home Component //
//////////////////////////

.dca-home-component-wrapper-L {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  max-height: 413px;
  margin-top: 10vh;
}

.dca-home-component-wrapper-R {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: auto;
  max-height: 413px;
  margin-top: 10vh;
}

.dca-cta-text-wrapper-L {
  font-family: 'ColfaxWebMedium';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 64px 0 64px;
  font-size: 22px;
  width: 50vw;
  max-height: 100%;
}

.dca-cta-text-wrapper-R {
  font-family: 'ColfaxWebMedium';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px 64px 0 64px;
  font-size: 22px;
  width: 50vw;
  max-height: 100%;
}

.dca-home-img-wrapper-L {
  display: flex block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 64px;
  width: 45vw;
  max-height: 413px;
}

.dca-home-img-wrapper-R {
  display: flex block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 64px;
  width: 45vw;
  max-height: 413px;
}

.dca-home-img {
  --position-a: initial;
  --position-b: initial;
  --scale: 1;

  width: 100%;
  height: auto;
  max-height: 413px;
  object-fit: cover;
  border-radius: 20px;
  object-position: var(--position-a) var(--position-b);
  transform: scale(var(--scale));
}

.dca-home-right-img-component {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
  height: 413px;
  margin-top: 10vh;
}

.dca-home-right-img {
  --position-a: initial;
  --position-b: initial;
  --scale: 1;

  width: 44.8%;
  object-fit: cover;
  margin-left: 64px;
  border-radius: 20px;
  object-position: var(--position-a) var(--position-b);
  transform: scale(var(--scale));
}

.dca-home-cta-button,
.dca-home-noauth-testscore-cta-button {
  margin-top: 40px;
  max-height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  border-radius: 10px;
  border: transparent;
  padding: 10px 20px;
  box-sizing: border-box;
  // font-size: calc(15px + 0.390625vw);
  font-family: 'ColfaxWebMedium';
  white-space: nowrap;
  overflow: auto;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  background: #DAF8B9;
  color: black;
}

// .dca-home-noauth-testscore-cta-button {
//   margin-top: 40px;
//   max-height: fit-content;
//   max-width: fit-content;
//   border-radius: 10px;
//   border: transparent;
//   padding: 10px 20px;
//   box-sizing: border-box;
//   // font-size: calc(15px + 0.390625vw);
//   font-family: 'ColfaxWebMedium';
//   white-space: nowrap;
//   overflow: auto;
//   display: flex;
//   align-items: center;
//   align-self: center;
//   text-align: center;
//   background: #DAF8B9
// }

.dca-testimonial-name {
  font-family: 'ColfaxWebMedium';
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dca-testimonial-text {
  font-family: 'ColfaxWebRegular';
  font-size: 20px;
  line-height: 31px;
  padding-top: 32px;
  text-align: center;
}

.dca-testimonial-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 80px;
  align-items: flex-start;
}

////////////////////////////////
//////// Mobile styling ////////
////////////////////////////////
@media only screen and (max-width: 768px) {

  .dca-home {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .dca-testimonial-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 80px;
    align-items: center;
  }

  //////// Mobile - Header 
  .dca-home-headline-container {
    min-height: fit-content;
    height: 33vh;
    margin-bottom: 5vh;
  }

  .dca-headline-img {
    width: 100%;
    object-fit: cover;
    object-position: 50% 28%;
  }

  .dca-home-header-wrapper {
    display: flex;
    flex-direction: column;
    width: 85%;
    max-width: 85%;
    position: absolute;
    top: 14%;
    left: 8%;
    border-radius: 34px;
    background: white;
    text-wrap: wrap;
    padding: 25px 35px;
  }

  .dca-home-header-1 {
    font-size: 16px;
    font-family: 'ColfaxWebMedium';
    text-wrap: wrap;
    width: fit-content;
  }

  .dca-home-header-2 {
    font-size: 12px;
    margin-top: 10px;
    font-weight: 900;
    text-wrap: wrap;
  }

  //////// Mobile - Components

  .dca-home-component-wrapper-L {
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-bottom: 10vh;
    justify-content: space-evenly;
    align-items: center;
    max-height: fit-content;
    margin-top: 0;
  }

  .dca-home-component-wrapper-R {
    display: flex;
    flex-direction: column-reverse;
    height: fit-content;
    margin-bottom: 10vh;
    justify-content: space-evenly;
    align-items: center;
    max-height: fit-content;
    margin-top: 0;
  }

  .dca-cta-text-wrapper-L {
    font-family: 'ColfaxWebMedium';
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
    text-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .dca-cta-text-wrapper-R {
    font-family: 'ColfaxWebMedium';
    display: flex;
    flex-direction: column;
    font-size: 14px;
    width: 100%;
    text-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .dca-home-img-wrapper-L {
    width: 90vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-content: center;
    align-items: center;
    align-self: center;
    margin: 0;
  }

  .dca-home-img-wrapper-R {
    width: 90vw;
    height: fit-content;
    display: flex;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-content: center;
    align-items: center;
    align-self: center;
    margin: 0;
  }

  .dca-home-img {
    --position-a: initial;
    --position-b: initial;
    --scale: 1;

    max-width: 100%;
    max-height: 100%;
    width: 280px;
    height: 275px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 20px;
    object-position: var(--position-a) var(--position-b);
    transform: scale(var(--scale));
  }

  .dca-home-cta-button,
  .dca-home-noauth-testscore-cta-button {
    margin-top: 40px;
    max-height: fit-content;
    min-width: fit-content;
    max-width: fit-content;
    border-radius: 10px;
    border: transparent;
    padding: 10px 20px;
    box-sizing: border-box;
    font-family: 'ColfaxWebMedium';
    white-space: nowrap;
    overflow: auto;
    display: flex;
    align-items: center;
    align-self: center;
    text-align: center;
    background: #DAF8B9;
    color: black;
  }

  // .dca-home-noauth-testscore-cta-button {
  //   margin-top: 40px;
  //   max-height: fit-content;
  //   max-width: fit-content;
  //   border-radius: 10px;
  //   border: transparent;
  //   padding: 10px 20px;
  //   box-sizing: border-box;
  //   font-family: 'ColfaxWebMedium';
  //   white-space: nowrap;
  //   overflow: auto;
  //   display: flex;
  //   align-items: center;
  //   align-self: center;
  //   text-align: center;
  //   background: #DAF8B9
  // }
}