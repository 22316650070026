:root {
  --font-size-editscore-title: 26px;
  --font-size-editscore-section: 20px;
  --font-size-editscore-field: 16px;

  --font-size-mobile-editscore-title: 16px;
  --font-size-mobile-editscore-section: 12px;
  --font-size-mobile-editscore-field: 10px;
}

// Details overlay {
.dca-testscore-overlay-explanation-title,
.dca-testscore-editscore-title,
.dca-testscore-editscore-sat-section-title,
.dca-testscore-editscore-act-section-title,
.dca-testscore-editscore-field-title,
.dca-testscore-editscore-field-title2,
.dca-testscore-editscore-discard-bttn,
.dca-testscore-editscore-submit-bttn {
  font-family: 'ColfaxWebMedium';
}

.dca-test-score-edit-your-score-profile-button {
  background: #DAF8B9;
  border: transparent;
  // border-radius: 0.65rem;
  border-radius: 10px;
  width: 260px;
  max-width: 260px;
  height: 50px;
  max-height: 60px;
  margin-top: 50px;
  font-family: 'ColfaxWebMedium';
  font-weight: 100 !important;
  font-size: 18px;
}

// .dca-test-score-show-colleges-of-interest {
//   font-family: 'ColfaxWebMedium';
//   font-weight: 100 !important;
// }

.dca-test-score-strategy-details-overlay-modal {
  min-width: 75vw;
  max-width: 75vw;
  min-height: 85vh;
  max-height: 85vh;
  padding: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}

.dca-testscore-overlay-explanation-title {
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: .12em;
  // padding: 10px;
  padding: 0px;
  margin-top: 60px;
  margin-bottom: 9px;
}

// Edit Your Score Profile Modal
.dca-testscore-editscore-title {
  // font-size: 38px;
  font-size: var(--font-size-editscore-title);
  align-self: center;
}

.dca-testscore-editscore-sat-section-title {
  // font-size: 30px;
  font-size: var(--font-size-editscore-section);
  margin-top: 35px
}

.dca-testscore-editscore-act-section-title {
  // font-size: 30px;
  font-size: var(--font-size-editscore-section);
  margin-top: 65px
}

.dca-testscore-editscore-field-title,
.dca-testscore-editscore-field-title2 {
  // font-size: 22px;
  font-size: var(--font-size-editscore-field);
  margin-bottom: 0.4rem;
}

.dca-testscore-editscore-field-title2 {
  margin-top: 23px;
}

.dca-testscore-editscore-input {
  padding: 0.5rem 1rem;
  background: rgba(250, 250, 250, 1);
  border: transparent;
  // width: 250px;
  // max-width: 250px;

  width: 220px;
  max-width: 220px;
}

.dca-testscore-editscore-discard-bttn {
  border: transparent;
  width: 135px;
  max-width: 135px;
  height: 60px;
  max-height: 60px;
  margin-top: 20px;
}

.dca-testscore-editscore-submit-bttn {
  background-color: #DAF8B9;
  border: transparent;
  width: 135px;
  max-width: 135px;
  height: 60px;
  max-height: 60px;
  margin-top: 20px;
  margin-left: 25px;
  color: black
}

.dca-editscore-profile-modal {
  width: 80vw;
  // max-width: 80vw;
  max-width: 700px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
}

.dca-editscoreprofile-modal-score-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%
}

////////////////////////////////
//////// Mobile styling ////////
////////////////////////////////
@media only screen and (max-width: 767px) {

  .dca-editscoreprofile-modal-score-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .dca-editscore-profile-modal {
    width: 90vw !important;
    max-width: 90vw;
  }

  .dca-testscore-editscore-input {
    width: 35vw;
    max-width: 35vw;
  }


  .dca-testscore-overlay-explanation {
    padding: 5px
  }

  .dca-test-score-strategy-details-overlay-modal {
    min-width: 90vw;
    max-width: 90vw;
    padding: 10px;
  }

  .dca-overlay-modal {
    display: block;
  }

  .dca-testscore-overlay-explanation-title {
    font-size: 14px;
    line-height: 12.4px;
    letter-spacing: .12em;
    padding: 5px;
    margin-top: 30px;
    margin-bottom: 6px;
  }

  .dca-testscore-editscore-title {
    font-size: var(--font-size-mobile-editscore-title);
  }

  .dca-testscore-editscore-sat-section-title {
    font-size: var(--font-size-mobile-editscore-section);
    margin-top: 18px;
    text-align: center;
  }

  .dca-testscore-editscore-act-section-title {
    // font-size: 30px;
    font-size: var(--font-size-mobile-editscore-section);
    margin-top: 32px;
    text-align: center
  }

  .dca-testscore-editscore-field-title,
  .dca-testscore-editscore-field-title2 {
    // font-size: 22px;
    font-size: var(--font-size-mobile-editscore-field);
    margin-bottom: 0.4rem;
  }

  .dca-testscore-editscore-field-title2 {
    margin-top: 14px;
  }

  // .dca-testscore-editscore-input {
  //   width: 270px;
  //   max-width: 270px;
  // }

  // .dca-testscore-editscore-discard-bttn {
  //   border: transparent;
  //   width: 135px;
  //   max-width: 135px;
  //   height: 60px;
  //   max-height: 60px;
  //   margin-top: 20px;
  // }

  // .dca-testscore-editscore-submit-bttn {
  //   background-color: #DAF8B9;
  //   border: transparent;
  //   width: 135px;
  //   max-width: 135px;
  //   height: 60px;
  //   max-height: 60px;
  //   margin-top: 20px;
  //   margin-left: 25px;
  // }

}