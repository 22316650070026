#dca-college-search-wrapper {
  margin: 0 auto;
  width: 100vw;
}

.dca-mobile-save-heart-icon {
  width: 20px;
  margin: 10px;
  cursor: pointer;
}

.dca-search-header-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin: 30px 35px 0 35px;
}

.dca-search-title {
  font-size: 30px;
  line-height: 36px;
  font-family: 'ColfaxWebMedium';
}

.dca-search-description {
  font-size: 21px;
  font-family: 'ColfaxWebRegular';
  margin-top: 15px;
  // margin-right: 40px;
}

.dca-search-header-title-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.dca-college-search-card-location-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  color: #585555;
  line-height: 20.4px;
  margin-top: 10px;
}

// .dca-search-header-bttn-container {
//   margin-right: 70px;
// }

.dca-search-header-bttn-container-double {
  // margin-right: 70px;
  display: flex;
  flex-direction: row;
}

.dca-search-header-bttn {
  min-height: fit-content;
  height: 100%;
  min-width: fit-content;
  border-radius: 10px;
  border: transparent;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: 400;
  white-space: nowrap;
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  background: linear-gradient(#DAF8B9 0%, #B7FFB5 100%);
  font-family: 'ColfaxWebMedium';
}

////// Cards //////
.dca-card-container,
.dca-card-container-test-score {
  // width: calc(100vw - 400px) !important;
  // min-width: fit-content;
  width: 100%;
  margin-left: 60px;
  // margin-right: 40px;
  display: flex;
  flex-direction: column;
}

.dca-card-container {
  min-width: fit-content;
}

.dca-card-container-test-score {
  min-width: 628px;
}

.dca-card-items-found-text {
  font-size: 21px;
  line-height: 25.2px;
  font-family: 'ColfaxWebMedium'
}

.dca-card-item,
.dca-test-score-card-item {
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #ededed;
  position: relative;

  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dca-card-item:hover,
.dca-test-score-card-item:hover {
  box-shadow: 0 5px 30px rgba(0, 0, 0, .05);
}

.dca-card-item-header {
  font-family: 'ColfaxWebMedium';
  font-size: 14px;
  line-height: 24.7px;
  letter-spacing: .12em;
}

.dca-card-item-nameField {
  font-size: 22px;
  line-height: 26.4px;
  font-family: 'ColfaxWebMedium';
  text-align: center;
}

.dca-card-item-nameField-major {
  font-size: 22px;
  line-height: 26.4px;
  font-family: 'ColfaxWebMedium';
  max-width: 70%;
  text-align: center;
}

.dca-card-item-text {
  font-family: 'ColfaxWebMedium';
}

.dca-college-card-data-wrapper,
.dca-test-score-card-data-wrapper {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  margin: 20px 0px 20px 0px;
}

.dca-college-card-data-wrapper {
  justify-content: space-evenly;
}

.dca-test-score-card-data-wrapper {
  justify-content: space-between
}

@media only screen and (max-width: 767px) {

  .dca-college-detail-industry-rankings-ul-mobile {
    box-sizing: border-box !important;
    overflow-x: scroll;
  }

}